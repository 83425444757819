import { useMutation } from "@tanstack/react-query";
import getEnvVariable from "../../getEnvVariable";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

const baseUrl = getEnvVariable("REACT_APP_ACCESS_MANAGER_API_BASE_URL");

const useUpdateUser = () => {
  const { update } = usePrivateAPIClient(baseUrl, "/api/v1/users", true);
  return useMutation({
    mutationFn: ({ id, data }) => update(id, data),
  });
};

export default useUpdateUser;
