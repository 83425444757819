import { useMutation } from "@tanstack/react-query";

import getEnvVariable from "../../getEnvVariable";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

const baseUrl = getEnvVariable("REACT_APP_ACCESS_MANAGER_API_BASE_URL");

const useDeleteUserAccessRolePermission = () => {
  const { deleteById } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/user-access-role-permissions",
    true
  );
  return useMutation({
    mutationFn: ({ roleId: id, operationId, permissionId, userId }) =>
      deleteById(id, { params: { operationId, permissionId, userId } }),
  });
};

export default useDeleteUserAccessRolePermission;
