import { useQuery } from "@tanstack/react-query";

import getEnvVariable from "../../getEnvVariable";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

const baseUrl = getEnvVariable("REACT_APP_ACCESS_MANAGER_API_BASE_URL");

const useGetJobTypes = () => {
  const { getAll } = usePrivateAPIClient(baseUrl, "/api/v1/job-types", true);
  return useQuery({
    queryKey: ["jobTypes"],
    queryFn: () => getAll(),
  });
};

export default useGetJobTypes;
