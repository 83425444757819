import React from "react";
import { Route } from "react-router-dom";
import Signin from "../feature-module/pages/login/signin";
import SigninTwo from "../feature-module/pages/login/signinTwo";
import SigninThree from "../feature-module/pages/login/signinFour";
import RegisterTwo from "../feature-module/pages/register/registerTwo";
import Register from "../feature-module/pages/register/register";
import RegisterThree from "../feature-module/pages/register/registerThree";
import Forgotpassword from "../feature-module/pages/forgotpassword/forgotpassword";
import EmailVerification from "../feature-module/pages/emailverification/emailverification";
import EmailverificationTwo from "../feature-module/pages/emailverification/emailverificationTwo";
import EmailverificationThree from "../feature-module/pages/emailverification/emailverificationThree";
import Twostepverification from "../feature-module/pages/twostepverification/twostepverification";
import TwostepverificationTwo from "../feature-module/pages/twostepverification/twostepverificationTwo";
import TwostepverificationThree from "../feature-module/pages/twostepverification/twostepverificationThree";
import Lockscreen from "../feature-module/pages/lockscreen";
import Error404 from "../feature-module/pages/errorpages/error404";
import Error500 from "../feature-module/pages/errorpages/ErrorBoundary";
import Comingsoon from "../feature-module/pages/comingsoon";
import Undermaintainence from "../feature-module/pages/undermaintainence";
import { all_routes } from "./all_routes";
import Resetpassword from "../feature-module/pages/resetpassword/resetpassword";
import Logout from "../feature-module/pages/logout/logout";
import ChangePassword from "../feature-module/pages/changepassword/changepassword";
import Users from "./../feature-module/usermanagement/users";
import RolesPermissions from "./../feature-module/usermanagement/rolespermissions";
import Permissions from "./../feature-module/usermanagement/permissions";
import DeleteAccount from "./../feature-module/usermanagement/deleteaccount";
import UserDetail from "../feature-module/usermanagement/userdetail";
import Dashboard from "../feature-module/dashboard/Dashboard";
const routes = all_routes;

export const protectedRoutes = [
  {
    id: 1,
    path: routes.dashboard,
    name: "home",
    element: <Dashboard />,
    allowedPermissions: { HOME: ["READ"] },
  },
  {
    id: 14,
    path: routes.logout,
    name: "logout",
    element: <Logout />,
    allowedPermissions: { HOME: ["READ"] },
  },
  {
    id: 18,
    path: routes.changepassword,
    name: "changepassword",
    element: <ChangePassword />,
    route: Route,
    allowedPermissions: { HOME: ["READ"] },
  },

  {
    id: 19,
    path: routes.users,
    name: "users",
    element: <Users />,
    route: Route,
    allowedPermissions: { HOME: ["READ"] },
  },
  {
    id: 20,
    path: routes.rolespermission,
    name: "rolespermission",
    element: <RolesPermissions />,
    route: Route,
    allowedPermissions: { HOME: ["READ"] },
  },
  {
    id: 21,
    path: routes.permissions,
    name: "permissions",
    element: <Permissions />,
    route: Route,
    allowedPermissions: { HOME: ["READ"] },
  },
  {
    id: 22,
    path: routes.deleteaccount,
    name: "deleteaccount",
    element: <DeleteAccount />,
    route: Route,
    allowedPermissions: { HOME: ["READ"] },
  },
  {
    id: 22,
    path: routes.userdetails,
    name: "userdetails",
    element: <UserDetail />,
    route: Route,
    allowedPermissions: { HOME: ["READ"] },
  },
];

export const pagesRoute = [
  {
    id: 1,
    path: routes.signin,
    name: "signin",
    element: <Signin />,
    route: Route,
  },
  {
    id: 2,
    path: routes.signintwo,
    name: "signintwo",
    element: <SigninTwo />,
    route: Route,
  },
  {
    id: 3,
    path: routes.signinthree,
    name: "signinthree",
    element: <SigninThree />,
    route: Route,
  },
  {
    id: 4,
    path: routes.register,
    name: "register",
    element: <Register />,
    route: Route,
  },
  {
    id: 5,
    path: routes.registerTwo,
    name: "registerTwo",
    element: <RegisterTwo />,
    route: Route,
  },
  {
    id: 6,
    path: routes.registerThree,
    name: "registerThree",
    element: <RegisterThree />,
    route: Route,
  },

  {
    id: 8,
    path: routes.forgotPassword,
    name: "forgotPassword",
    element: <Forgotpassword />,
    route: Route,
  },
  {
    id: 9,
    path: routes.resetpassword,
    name: "resetpassword",
    element: <Resetpassword />,
    route: Route,
  },

  {
    id: 12,
    path: routes.emailverification,
    name: "emailverification",
    element: <EmailVerification />,
    route: Route,
  },
  {
    id: 12,
    path: routes.emailverificationTwo,
    name: "emailverificationTwo",
    element: <EmailverificationTwo />,
    route: Route,
  },
  {
    id: 13,
    path: routes.emailverificationThree,
    name: "emailverificationThree",
    element: <EmailverificationThree />,
    route: Route,
  },
  {
    id: 14,
    path: routes.twostepverification,
    name: "twostepverification",
    element: <Twostepverification />,
    route: Route,
  },
  {
    id: 15,
    path: routes.twostepverificationTwo,
    name: "twostepverificationTwo",
    element: <TwostepverificationTwo />,
    route: Route,
  },
  {
    id: 16,
    path: routes.twostepverificationThree,
    name: "twostepverificationThree",
    element: <TwostepverificationThree />,
    route: Route,
  },
  {
    id: 17,
    path: routes.lockscreen,
    name: "lockscreen",
    element: <Lockscreen />,
    route: Route,
  },
  {
    id: 18,
    path: routes.error404,
    name: "error404",
    element: <Error404 />,
    route: Route,
  },
  {
    id: 19,
    path: routes.error500,
    name: "error500",
    element: <Error500 />,
    route: Route,
  },
  {
    id: 20,
    path: routes.comingsoon,
    name: "comingsoon",
    element: <Comingsoon />,
    route: Route,
  },
  {
    id: 21,
    path: routes.undermaintenance,
    name: "undermaintenance",
    element: <Undermaintainence />,
    route: Route,
  },
];
