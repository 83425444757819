import React from "react";
import PropTypes from "prop-types";
const DateInput = ({ register, label, errors }) => {
  return (
    <>
      <label className="form-label">{label}</label>
      <input {...register} type="date" className="form-control" />
      {errors && <p className="text-danger">{errors.message}</p>}
    </>
  );
};

DateInput.propTypes = {
  register: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  errors: PropTypes.string,
};

export default DateInput;
