import { useMutation } from "@tanstack/react-query";

import getEnvVariable from "../../getEnvVariable";
import usePublicAPIClient from "../services/usePublicAPIClient";

const baseUrl = getEnvVariable("REACT_APP_ACCESS_MANAGER_API_BASE_URL");

const useResetPassword = () => {
  const { create } = usePublicAPIClient(baseUrl, "/api/v1/reset-password");
  return useMutation({
    mutationFn: (data) => create(data),
  });
};

export default useResetPassword;
