import React, { useEffect, useState } from "react";
import { Country, City } from "country-state-city";
import withReactContent from "sweetalert2-react-content";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod.js";
import useAddUser from "../../hooks/useAddUser";
import TextInput from "../../form-components/TextInput";
import SelectInput from "../../form-components/SelectInput";
import Button from "../../form-components/Button";
import useAuth from "./../../hooks/useAuth";
import useGetJobTypes from "./../../hooks/useGetJobTypes";
import useGetUserAccessRoles from "./../../hooks/useGetUserAccessRoles";
import DateInput from "../../form-components/DateInput";

const schema = z.object({
  lastName: z.string(),
  middleName: z.string(),
  firstName: z.string(),
  country: z.string(),
  city: z.string(),
  phone: z.string(),
  address: z.string(),
  email: z.string(),
  hireDate: z.string(),
  monthlySalary: z.number(),
  job: z.union([z.number(), z.string()]),
  role: z.union([z.number(), z.string()]),
  id: z.union([z.number(), z.string()]).optional(),
});

const AddUser = () => {
  const MySwal = withReactContent(Swal);

  const { auth } = useAuth();
  const addUser = useAddUser();
  const getJobTypes = useGetJobTypes();
  const getUserAccessRoles = useGetUserAccessRoles();

  const [countries, setCountries] = useState([]);

  const [cities, setCities] = useState([]);

  function loadCitiesOfACountry(countryCode) {
    const citiesData = City.getCitiesOfCountry(countryCode);
    if (citiesData) {
      const cities = citiesData?.map((item) => ({
        label: item.name,
        value: item.name,
      }));
      setCities(cities);
    }
  }

  function handleChange(event) {
    const { currentTarget: input } = event;
    loadCitiesOfACountry(input.value);
  }

  useEffect(() => {
    const countriesData = Country.getAllCountries();
    const countries = countriesData?.map((item) => ({
      label: item.name,
      value: item.isoCode,
    }));
    setCountries(countries);
  }, []);

  const joTypeOptions = Object.entries(
    getJobTypes.data?.data?.jobTypes ?? {}
  ).map(([label]) => ({
    label,
    value: label,
  }));

  const typeOptions =
    getUserAccessRoles?.data?.data?.userAccessRoles?.map((accessRole) => {
      return {
        label: accessRole.name,
        value: accessRole.name,
      };
    }) || [];

  const showCreateConfirmation = (handleCreateConfirmation) => {
    MySwal.fire({
      title: "Are you sure you want to create this user?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, order it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleCreateConfirmation();
      } else {
        MySwal.close();
      }
    });
  };

  const handleCreateConfirm = (data) => {
    const user = {
      personalInformation: {
        lastName: data.lastName,
        middleName: data.middleName,
        firstName: data.firstName,
        country: data.country,
        city: data.city,
        phone: data.phone,
        address: data.address,
        email: data.email,
      },
      employmentInformation: {
        hireDate: data.hireDate,
        monthlySalary: data.monthlySalary,
        job: data.job,
      },
      accessInformation: {
        role: data.role,
      },
      createdByUserId: auth.userId,
    };
    addUser.mutate(user, {
      onSuccess: () => {
        MySwal.fire({
          title: "Created!",
          text: "The user has been created.",
          icon: "success",
          confirmButtonText: "OK",
        });
      },
      onError: () => {
        MySwal.fire({
          title: "Not Created!",
          text: "Failed to create user.",
          icon: "error",
          confirmButtonText: "OK",
        });
      },
    });
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    try {
      showCreateConfirmation(() => handleCreateConfirm(data));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      {/* Add User */}
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Create User</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <TextInput
                        label="Last Name"
                        register={register("lastName")}
                        errors={errors.lastName}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="Middle Name"
                        register={register("middleName")}
                        errors={errors.middleName}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="First Name"
                        register={register("firstName")}
                        errors={errors.firstName}
                      />
                    </div>
                    <div className="mb-3">
                      <SelectInput
                        onChange={handleChange}
                        label="Country"
                        register={register("country")}
                        options={countries}
                        errors={errors.country}
                      />
                    </div>
                    <div className="mb-3">
                      <SelectInput
                        label="City"
                        register={register("city")}
                        options={cities}
                        errors={errors.city}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="Phone"
                        register={register("phone")}
                        errors={errors.phone}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="Address"
                        register={register("address")}
                        errors={errors.address}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="Email"
                        register={register("email")}
                        errors={errors.email}
                      />
                    </div>
                    <div className="mb-3">
                      <DateInput
                        label="Hire Date"
                        register={register("hireDate")}
                        errors={errors.hireDate}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="Monthly Salary"
                        register={register("monthlySalary", {
                          valueAsNumber: true,
                        })}
                        errors={errors.monthlySalary}
                        options={{ valueAsNumber: true }}
                      />
                    </div>
                    <div className="mb-3">
                      <SelectInput
                        label="Job"
                        register={register("job")}
                        options={joTypeOptions}
                        errors={errors.job}
                      />
                    </div>
                    <div className="mb-3">
                      <SelectInput
                        label="User Role"
                        register={register("role")}
                        options={typeOptions}
                        errors={errors.role}
                      />
                    </div>
                    <div className="modal-footer-btn">
                      <Button color="info" label="Create User" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add User */}
    </div>
  );
};

export default AddUser;
