import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ChevronUp, RotateCcw } from "feather-icons-react/build/IconComponents";
import { setToogleHeader } from "../../core/redux/action";
import { PlusCircle } from "react-feather";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import AddRole from "../../core/modals/usermanagement/addrole";
import EditRole from "../../core/modals/usermanagement/editrole";
import { all_routes } from "../../Router/all_routes";
import useGetUserAccessRoles from "./../../core/hooks/useGetUserAccessRoles";
import useAuth from "../../core/hooks/useAuth";
import useDeleteUserAccessRole from "./../../core/hooks/useDeleteUserAccessRole";

const RolesPermissions = () => {
  const { auth } = useAuth();
  const route = all_routes;
  const data = useSelector((state) => state.toggle_header);
  const [currentRecord, setCurrentRecord] = useState();
  const dispatch = useDispatch();

  const { data: userAccessRoles, isSuccess } = useGetUserAccessRoles();

  const deleteUserAccessRole = useDeleteUserAccessRole();

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  const columns = [
    {
      title: "Role Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.description.length - b.description.length,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
              onClick={() => setCurrentRecord(record)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            {}
            <Link
              className="me-2 p-2"
              to={route.permissions}
              state={{ id: record.id }}
            >
              <i
                data-feather="sheild"
                className="feather feather-shield shield"
              ></i>
            </Link>
            <Link className="confirm-text p-2" to="#">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() =>
                  showDeleteConfirmationAlert(() =>
                    handleDeleteConfirm(record.id)
                  )
                }
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const MySwal = withReactContent(Swal);
  const showDeleteConfirmationAlert = (handleClickConfirm) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",

      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickConfirm();
      } else {
        MySwal.close();
      }
    });
  };

  const handleDeleteConfirm = (id) => {
    deleteUserAccessRole.mutate(
      { id, userId: auth.userId },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Deleted!",
            text: "The role has been deleted.",
            icon: "success",
            confirmButtonText: "OK",
          });
        },
        onError: () => {
          MySwal.fire({
            title: "Not Deleted!",
            text: "Failed to delete role.",
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Roles &amp; Permission</h4>
                <h6>Manage your roles</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <a
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-units"
              >
                <PlusCircle className="me-2" />
                Add New Role
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                {isSuccess && (
                  <Table
                    columns={columns}
                    dataSource={userAccessRoles.data.userAccessRoles}
                  />
                )}
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <AddRole />
      <EditRole currentRecord={currentRecord} />
    </div>
  );
};

export default RolesPermissions;
