import React from "react";

import * as Icon from "react-feather";

export const SidebarData = [
  {
    label: "User Management",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "User Management",
    submenuItems: [
      {
        label: "Users",
        link: "/users",
        icon: <Icon.UserCheck />,
        showSubRoute: false,
      },
      {
        label: "Roles & Permissions",
        link: "/roles-permissions",
        icon: <Icon.UserCheck />,
        showSubRoute: false,
      },
    ],
  },
  {
    label: "Settings",
    submenu: true,
    showSubRoute: false,
    submenuHdr: "Settings",
    submenuItems: [
      {
        label: "Change Password",
        link: "/change-password",
        icon: <Icon.Key />,
        showSubRoute: false,
      },
      {
        label: "Logout",
        link: "/logout",
        icon: <Icon.LogOut />,
        showSubRoute: false,
      },
    ],
  },
];
