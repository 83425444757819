// src/utils/getEnvVariable.js

/**
 * Gets an environment variable by its name.
 * If the variable is not found, it throws an error.
 *
 * @param {string} name - The name of the environment variable.
 * @returns {string} - The value of the environment variable.
 */
const getEnvVariable = (name) => {
  // eslint-disable-next-line no-restricted-globals
  const value = process.env[name];

  if (value === undefined) {
    throw new Error(`Environment variable ${name} is not defined`);
  }

  return value;
};

export default getEnvVariable;
