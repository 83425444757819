import { useQuery } from "@tanstack/react-query";

import getEnvVariable from "../../getEnvVariable";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

const baseUrl = getEnvVariable("REACT_APP_ACCESS_MANAGER_API_BASE_URL");

const useGetUserAccessRolePermissions = () => {
  const { getAll } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/user-access-role-permissions",
    true
  );
  return useQuery({
    queryKey: ["userAccessRolePermissions"],
    queryFn: () => getAll(),
  });
};

export default useGetUserAccessRolePermissions;
