import { useMutation } from "@tanstack/react-query";

import getEnvVariable from "../../getEnvVariable";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

const baseUrl = getEnvVariable("REACT_APP_ACCESS_MANAGER_API_BASE_URL");

const useAddUserAccessRole = () => {
  const { create } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/user-access-roles",
    true
  );
  return useMutation({
    mutationFn: (data) => create(data),
  });
};

export default useAddUserAccessRole;
