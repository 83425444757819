import useAxiosPrivate from "./useAxiosPrivate";

const usePrivateAPIClient = (baseURL, endpoint, isWithCredentials) => {
  const axiosPrivate = useAxiosPrivate(baseURL, isWithCredentials);

  const getAll = async (config = {}) => {
    const response = await axiosPrivate.get(endpoint, config);
    return response.data;
  };

  const getById = async (id, config = {}) => {
    const response = await axiosPrivate.get(`${endpoint}/${id}`, config);
    return response.data;
  };

  const deleteById = async (id, config = {}) => {
    const response = await axiosPrivate.delete(`${endpoint}/${id}`, config);
    return response.data;
  };

  const create = async (
    data = {},
    config = { headers: { "Content-Type": "application/json" } }
  ) => {
    const token = axiosPrivate.defaults.headers["Authorization"];
    console.log(
      "Creating with data:",
      data,
      "Config:",
      config,
      "Token:",
      token
    ); // Debug log

    const response = await axiosPrivate.post(endpoint, data, config);
    console.log("Response data:", response.data); // Debug log
    return response.data;
  };

  const update = async (
    id,
    data,
    config = { headers: { "Content-Type": "application/json" } }
  ) => {
    const token = axiosPrivate.defaults.headers["Authorization"];
    console.log(
      "Creating with data:",
      data,
      "Config:",
      config,
      "Token:",
      token
    ); // Debug log
    const response = await axiosPrivate.put(`${endpoint}/${id}`, data, config);
    console.log("Response data:", response.data); // Debug log
    return response.data;
  };

  return { getAll, getById, deleteById, create, update };
};

export default usePrivateAPIClient;
