import { useQuery } from "@tanstack/react-query";
import getEnvVariable from "../../getEnvVariable";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

const baseUrl = getEnvVariable("REACT_APP_ACCESS_MANAGER_API_BASE_URL");

const useGetUserAccessRoles = () => {
  const { getAll } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/user-access-roles",
    true
  );
  return useQuery({
    queryKey: ["userAccessRoles"],
    queryFn: () => getAll(),
  });
};

export default useGetUserAccessRoles;
